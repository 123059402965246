const projects = [
  {
    id: 1,
    title: 'Eat Seasonal',
    tech: [
      'All',
      'React',
      'Express',
      'Node.js',
      'Teamwork',
      'PostgreSQL',
      'Auth',
    ],
    desktop: true,
    mobile: true,
    githubUrl:
      'https://github.com/SchoolOfCode/final-project-repo-restful-journey/blob/main/README.md',
    websiteUrl: 'https://seasonal-food.netlify.app/',
    introduction:
      'Challenge: building a sustainable world. The app displays national produce in season, and suggests recipes containing them.  Allows the user to create an account, shopping list, and save favourite recipes as well.',
    imageUrl: './images/nourish.png',
    imageAlt: 'Eat Seasonal',
  },
  {
    id: 4,
    title: 'Weather App',
    tech: ['All', 'React', 'Personal', 'CSS'],
    desktop: true,
    mobile: true,
    githubUrl: 'https://github.com/Luis0lo/Hold_React',
    websiteUrl: 'https://holdreact.netlify.app/weather',
    introduction:
      'The main features are the possibility to store favourite locations and check how the weather was like on your birthday. Weather is displayed in charts and locations using google maps API.',
    imageUrl: '/images/weather.jpg',
    imageAlt: 'Weather App',
  },
  {
    id: 8,
    title: 'Chauffeur Service',
    tech: ['All', 'React', 'Personal', 'Freelance', 'CSS', 'SCSS'],
    desktop: true,
    mobile: true,
    githubUrl: 'https://github.com/Luis0lo/traslados_ideal',
    websiteUrl: 'https://trasladosideal.netlify.app/#bienvenido',
    introduction:
      'This project was built for a South American client with the ambition to attract more clients for its private chauffeur company. In this project, I used scss for the first time.',
    imageUrl: '/images/chauffeur.jpg',
    imageAlt: 'Chauffeur landing page',
  },
  {
    id: 2,
    title: 'onePlace',
    tech: [
      'All',
      'React',
      'Express',
      'Node.js',
      'CSS',
      'Teamwork',
      'PostgreSQL',
    ],
    desktop: true,
    mobile: false,
    githubUrl: 'https://github.com/Luis0lo',
    websiteUrl: 'https://agitated-northcutt-ed9a89.netlify.app/',
    introduction:
      'This was a week challenge to solve a Bootcamper problem. onePlace offers direct access to daily needed links and the possibility to add new links vetted by coaches and tutors. Focused on React and CRUD operations.',
    imageUrl: '/images/onePlace.jpg',
    imageAlt: 'One place',
  },
  {
    id: 3,
    title: 'Investiment Tracker',
    tech: [
      'All',
      'React',
      'Express',
      'Node.js',
      'Personal',
      'PostgreSQL',
      'Chakra UI',
    ],
    desktop: true,
    mobile: true,
    githubUrl: 'https://github.com/Luis0lo/Hold_React',
    websiteUrl: 'https://holdreact.netlify.app/holdings',
    introduction:
      'Get the live balance of your investments in your preferred currency. Check for inspirational quotes to keep holding your shares, contribute with more quotes, and edit or delete them if you like so.',
    imageUrl: '/images/hold.jpg',
    imageAlt: 'Investment tracker',
  },

  {
    id: 5,
    title: 'El Duque Real',
    tech: ['All', 'Freelance', 'Bootstrap', 'Personal'],
    desktop: true,
    mobile: true,
    githubUrl: 'https://github.com/Luis0lo/Duque-Real',
    websiteUrl: 'https://www.elduquereal.com/',
    introduction:
      'This project came to fulfill the needs the client had to be present on the web after COVID hit. Built using Bootstrap having peculiar attention to SEO best practices.',
    imageUrl: '/images/duquereal.jpg',
    imageAlt: 'Static Website El Duque Real',
  },
  {
    id: 6,
    title: 'Islandscape',
    tech: ['All', 'Javascript', 'Personal', 'Bootstrap'],
    desktop: true,
    mobile: true,
    githubUrl: 'https://github.com/Luis0lo/Static-Web',
    websiteUrl: 'https://luis0lo.github.io/Static-Web/',
    introduction:
      'Fictional off-road tours company multi-page, each one with a unique responsive layout, to keep users engaged. It was built with a carousel, photo gallery, iframe video, cards, and some 3rd party scripts.',
    imageUrl: '/images/island.jpg',
    imageAlt: 'Static Website Islandscape',
  },
  {
    id: 7,
    title: 'Fields',
    tech: ['All', 'MongoDB', 'Express', 'Personal', 'Bootstrap'],
    desktop: true,
    mobile: false,
    githubUrl: 'https://github.com/Luis0lo/field',
    websiteUrl: 'https://fieldsapp.herokuapp.com/',
    introduction:
      'Full stack app where users can submit, edit and delete their favourite fields and respective locations. They are also allowed to comment on posts. ',
    imageUrl: '/images/fields.jpg',
    imageAlt: 'Fields',
  },
  {
    id: 7,
    title: 'CrochetIsland E-comm',
    tech: [
      'All',
      'MongoDB',
      'Express',
      'Personal',
      'Redux',
      'Styled-components',
    ],
    desktop: true,
    mobile: true,
    githubUrl: 'https://github.com/Luis0lo/eCommerce',
    websiteUrl: 'https://crochetisland.netlify.app/',
    introduction:
      "Full stack eCommerce app project in progress. The app's main goal is to explore Redux and use the payment processing platform Stripe.",
    imageUrl: '/images/crochetIsland.jpg',
    imageAlt: 'Fields',
  },
  {
    id: 9,
    title: 'Coming Soon',
    tech: [
      'All',
      'CSS',
      'SCSS',
      'React',
      'Personal',
      'Teamwork',
      'Freelance',
      'Bootstrap',
      'Chakra UI',
      'MongoDB',
      'PostgreSQL',
      'Auth',
      'Node.js',
      'Redux',
      'Styled-components',
    ],
    desktop: false,
    mobile: false,
    githubUrl: '',
    websiteUrl: '',
    introduction: 'Working on it',
    imageUrl: '/images/crochetIsland.jpg',
    imageAlt: 'Fields',
  },
];
export default projects;
